import { THistoryVersionPreviewAction } from '@hocuspocus/provider';
import CollaborationHistory, {
  CollabHistoryVersion,
  CollabOnUpdateProps,
} from '@tiptap-pro/extension-collaboration-history';
import { useEditor } from '@tiptap/react';
import React, { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

import { useTiptapCollaboration } from '../contexts/TiptapCollaboration';
import useContentWatcher from '../hooks/useContentWatcher';
import { EditorExtensions } from '../shared/constants/extensions';
import Content from './Content';
import { Body, Footer, Preview, Sidebar } from './styles';
import Submit from './Submit';
import Title from './Title';
import Versions from './Versions';

/**
 * Editor component for displaying and managing Document history.
 * Should only contain values for instantiating, updating, or reading from the useEditor hook.
 * Displays all versions and previews the selected version.
 * Allows the user to create a new version from the selected version.
 *
 * @returns React component for the editor
 */
const Editor = () => {
  const [versions, setVersions] = useState<CollabHistoryVersion[]>([]);
  const [selectedVersion, setSelectedVersion] = useState<CollabHistoryVersion>();

  const { palettes } = useTheme();
  const { provider } = useTiptapCollaboration();
  const editor = useEditor(
    {
      autofocus: false,
      editable: false,
      shouldRerenderOnTransaction: false,
      extensions: [
        ...EditorExtensions({ isEditable: false, palettes }),
        CollaborationHistory.configure({
          provider,
          onUpdate: (payload: CollabOnUpdateProps) => {
            // the provider sends an empty versions array multiple times during initialization
            if (payload.versions.length === 0) return;

            // set versions only once and ignore later payload.versions that may be sent by the provider
            setVersions((prevVersions) =>
              prevVersions.length === 0 ? payload.versions : prevVersions
            );
          },
        }),
      ],
    },
    [provider]
  );
  useContentWatcher({ editor, provider });
  useEffect(() => {
    if (!provider || selectedVersion || versions.length === 0) return;

    const initialVersion = versions[versions.length - 1];
    setSelectedVersion(initialVersion);
    const action: THistoryVersionPreviewAction = {
      action: 'version.preview',
      version: initialVersion.version,
    };
    provider.sendStateless(JSON.stringify(action));
  }, [selectedVersion, setSelectedVersion, provider, versions]);

  return (
    <>
      <Body>
        <Preview>
          <Content editor={editor} />
        </Preview>
        <Sidebar>
          <Title />
          <Versions
            selectedVersion={selectedVersion}
            setSelectedVersion={setSelectedVersion}
            versions={versions}
          />
        </Sidebar>
      </Body>
      <Footer>
        {selectedVersion && <Submit editor={editor} selectedVersion={selectedVersion} />}
      </Footer>
    </>
  );
};

export default Editor;
