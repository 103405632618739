import { EasyllamaTrainingsParams } from '../../../../../redux/services/resourceApis/easyllama/types';

type Action = { type: 'changePage'; page: number };

export const initialState: EasyllamaTrainingsParams = { page: 1 };

export const reducer = (
  state: EasyllamaTrainingsParams,
  action: Action
): EasyllamaTrainingsParams => {
  switch (action.type) {
    case 'changePage': {
      return { ...state, page: action.page };
    }
    default:
      return state;
  }
};
