import styled, { css } from 'styled-components';

export const CoursesWrapper = styled.div`
  ${({ theme: { constants } }) => css`
    display: flex;
    flex-direction: column;
    gap: ${constants.spacerMd1};
  `};
`;

export const PaginationWrapper = styled.div`
  margin-top: ${({ theme: { constants } }) => constants.spacerLg2};
`;
