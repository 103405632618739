import { toCamelCase } from '../../../../lib/keyFormatConverter';
import { trainualApi } from '../../trainualService';
import { EasyllamaTrainingsParams, EasyllamaTrainingsResponse } from './types';

const easyllamaApi = trainualApi.injectEndpoints({
  endpoints: (builder) => ({
    getEasyllamaTrainings: builder.query<EasyllamaTrainingsResponse, EasyllamaTrainingsParams>({
      query: ({ page }) => ({
        url: 'ajax/easyllama/trainings',
        params: { page },
      }),
      transformResponse: (response: EasyllamaTrainingsResponse) => {
        return toCamelCase(response);
      },
    }),
  }),
});

export const { useGetEasyllamaTrainingsQuery } = easyllamaApi;
