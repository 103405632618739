import React, { useReducer } from 'react';

import { useGetEasyllamaTrainingsQuery } from '../../../../../redux/services/resourceApis/easyllama/easyllamaApi';
import { EasyllamaTrainingsResponse } from '../../../../../redux/services/resourceApis/easyllama/types';
import Pagination from '../../../../design_system/Triage/Paginate';
import CourseOutline from '../CourseOutline/CourseOutline';
import { initialState, reducer } from './reducer';
import { CoursesWrapper, PaginationWrapper } from './styles';

const Courses = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { data: paginatedTrainingsData, isFetching } = useGetEasyllamaTrainingsQuery({
    page: state.page,
  });

  // TODO GS3: Add skeleton loader
  if (isFetching) return <p>Loading...</p>;

  const { trainings, totalTrainingsCount, totalPages, limitValue } =
    paginatedTrainingsData as EasyllamaTrainingsResponse;
  return (
    <>
      <CoursesWrapper>
        {trainings.map((course) => (
          <CourseOutline {...course} key={course.id} />
        ))}
      </CoursesWrapper>
      <PaginationWrapper>
        <Pagination
          activePage={state.page}
          itemsCountPerPage={limitValue}
          onChange={(page) => dispatch({ type: 'changePage', page })}
          showPaginationDetails
          totalItemsCount={totalTrainingsCount}
          totalPages={totalPages}
        />
      </PaginationWrapper>
    </>
  );
};

export default Courses;
